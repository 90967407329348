import revive_payload_client_Frd8gASYWa from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aQoh9E5uaZ from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/src/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_L4dx7UUNO5 from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_PIdCykQ0f0 from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_kCUITUWXUN from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import floating_vue_BiOD74u9sH from "/app/src/plugins/floating-vue.ts";
import maska_UHaKf2z1iQ from "/app/src/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/app/src/plugins/sentry.client.ts";
import vk_client_PUbypKZsNX from "/app/src/plugins/vk.client.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  check_outdated_build_client_yxgjJ9XxnV,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  plugin_L4dx7UUNO5,
  plugin_PIdCykQ0f0,
  chunk_reload_client_kCUITUWXUN,
  floating_vue_BiOD74u9sH,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  vk_client_PUbypKZsNX
]