import debounce from 'lodash.debounce';
import { defineStore } from 'pinia';
import type { AnalyticEvent, Service } from '~/api/types';
import { sendEvents as sendEventsApi } from '~/api/analytics';

export const useAnalyticsStore = defineStore('analytics', () => {
  const events = ref<AnalyticEvent[]>([]);
  const hasSentEvents = ref<boolean>(false);

  const sendEvents = () => {
    if (hasSentEvents.value) return;

    const eventsSnapshot: AnalyticEvent[] = [...events.value];
    events.value = [];

    hasSentEvents.value = true;
    sendEventsApi(eventsSnapshot).finally(() => {
      hasSentEvents.value = false;
    });
  };

  const sendEventsDebounced = debounce(sendEvents, 10000, { leading: true });
  const addEvent = (event: AnalyticEvent) => {
    event.ts = new Date().getTime();
    console.log('addEvent', event.event_name);
    events.value = [...events.value, event];
    sendEventsDebounced();
  };

  const openPage = (screen: string) => {
    return addEvent({
      event_name: 'openPage',
      screen,
    });
  };

  const openProjectPage = (projectId: number) => {
    return addEvent({
      event_name: 'openProjectPage',
      project_id: projectId,
    });
  };

  const clickActionFloatBlock = (projectId: number) => {
    return addEvent({
      event_name: 'clickActionFloatBlock',
      project_id: projectId,
    });
  };

  const toggleServices = (projectId: number, services: Service[]) => {
    return addEvent({
      event_name: 'toggleServices',
      project_id: projectId,
      services,
    });
  };
  const enableService = (projectId: number, service: Service) => {
    return addEvent({
      event_name: 'enableService',
      project_id: projectId,
      services: [service],
    });
  };

  const disableService = (projectId: number, service: Service) => {
    return addEvent({
      event_name: 'disableService',
      project_id: projectId,
      services: [service],
    });
  };

  const enterPrompt = (query: string) => {
    return addEvent({
      event_name: 'enterPrompt',
      query,
    });
  };

  const submitPrompt = (query: string) => {
    return addEvent({
      event_name: 'submitPrompt',
      query,
    });
  };

  const submitPayForm = (projectId: number, amount: number, services: Service[]) => {
    return addEvent({
      event_name: 'submitPayForm',
      project_id: projectId,
      amount,
      services,
    });
  };

  const downloadText = (projectId: number) => {
    return addEvent({
      event_name: 'downloadText',
      project_id: projectId,
    });
  };

  const downloadSlides = (projectId: number) => {
    return addEvent({
      event_name: 'downloadSlides',
      project_id: projectId,
    });
  };

  const openServiceDescription = (service: Service, projectId: number) => {
    return addEvent({
      event_name: 'openServiceDescription',
      project_id: projectId,
      services: [service],
    });
  };

  const hasSeenReviews = () => {
    return addEvent({
      event_name: 'hasSeenReviews',
    });
  };

  const telegramPopupShow = () => {
    return addEvent({
      event_name: 'telegramPopupShow',
    });
  };

  const telegramPopupClose = () => {
    return addEvent({
      event_name: 'telegramPopupClose',
    });
  };

  const telegramPopupSubscribe = () => {
    return addEvent({
      event_name: 'telegramPopupSubscribe',
    });
  };

  const downloadProjectExample = (projectId: number) => {
    return addEvent({
      event_name: 'downloadProjectExample',
      project_id: projectId,
    });
  };

  const generationStart = (projectId: number) => {
    return addEvent({
      event_name: 'generationStart',
      project_id: projectId,
    });
  };

  const generationEnd = (projectId: number) => {
    return addEvent({
      event_name: 'generationEnd',
      project_id: projectId,
    });
  };

  const clickTinkoffTrustBadge = () => {
    return addEvent({
      event_name: 'clickTinkoffTrustBadge',
    });
  };

  return {
    events,
    addEvent,
    openPage,
    hasSeenReviews,
    openProjectPage,
    clickActionFloatBlock,
    toggleServices,
    enterPrompt,
    submitPrompt,
    submitPayForm,
    enableService,
    disableService,
    downloadText,
    downloadSlides,
    openServiceDescription,
    telegramPopupShow,
    telegramPopupClose,
    telegramPopupSubscribe,
    downloadProjectExample,
    generationStart,
    generationEnd,
    clickTinkoffTrustBadge,
  };
});
